@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}
.main-header {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.header-block {
  padding: 18px 0;
}
.header-block .brand-logo {
  height: 40px;
  vertical-align: middle;
}

.landing-section {
  position: relative;
  overflow: hidden;
  background-color: #fff;
}
.landing-wrapper {
  z-index: 8;
  position: relative;
}
.landing-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
}

.landing-graphics {
  width: 100%;
}

h1.landing-heading {
  font-size: 20px;
  font-weight: 700;
  color: #1e2028;
  margin-bottom: 22px;
  font-family: "Roboto", sans-serif;
}

ul.landing-list {
  list-style-type: decimal;
  padding-left: 18px;
  margin-bottom: 0;
}

ul.landing-list li {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #282a36;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.landing-section::after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 5;
  background-color: #ccf2ff;
}

@media screen and (min-width: 390px) and (max-width: 767px) {
  .landing-content {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .landing-section {
    min-height: calc(100dvh - 118px);
  }
  .landing-section::after {
    left: 0;
    width: 100%;
    height: 310px;
  }
  .landing-graphics img {
    max-width: 100%;
    max-height: 251px;
    display: block;
    margin: 30px auto;
  }
  .landing-content {
    padding: 20px;
    margin-top: 18px;
  }
}

@media screen and (min-width: 768px) {
  .landing-section::after {
    right: 0;
    width: 50%;
    height: 100%;
  }
  .landing-content {
    padding-right: 30px;
  }
  h1.landing-heading {
    font-size: 32px;
  }
  .landing-graphics {
    padding-left: 40px;
  }
  .landing-block {
    min-height: calc(100dvh - 118px);
    max-width: 455px;
    margin-left: 0;
    margin-right: 0;
  }
  .landing-graphics img {
    max-width: 100%;
    vertical-align: middle;
  }
}

.main-section {
  min-height: calc(100dvh - 76px - 52px);
}
.content-section {
  max-width: 930px;
  margin: auto;
  width: 100%;
  padding: 70px 0;
}

.content-section .content-title {
  font-weight: 600;
  font-size: 24px;
  color: #000;
}

.content-section .boxed-view-content {
  border: 1px solid #e1e3ee;
  border-radius: 8px;
  overflow: hidden;
}

.content-section .graphics-bg {
  background: rgb(227, 247, 240);
  background: -moz-linear-gradient(
    135deg,
    rgba(227, 247, 240, 1) 18%,
    rgba(224, 243, 247, 1) 100%
  );
  background: -webkit-linear-gradient(
    135deg,
    rgba(227, 247, 240, 1) 18%,
    rgba(224, 243, 247, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(227, 247, 240, 1) 18%,
    rgba(224, 243, 247, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e3f7f0",endColorstr="#e0f3f7",GradientType=1);
}

.content-section .graphics-bg img {
  height: 299px;
}

.data-table span.table-label {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  font-family: "Poppins", sans-serif;
}

.data-table span.table-value {
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  font-family: "Poppins", sans-serif;
}
.association-logo span.title-span {
  display: block;
  margin-bottom: 8px;
}
/* .association-logo img {
  height: 22px;
} */
.association-logo img {
  max-height: 34px;
  max-width: 100%;
}
.main-footer {
  background-color: #fff;
  width: 100%;
  padding: 14px 0;
  height: 52px;
}

.app-version {
  color: #656566;
  font-size: 12px;
  font-weight: 400;
}

.copyright-text {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

/* EMPTY PAGE */
.content-error-section {
  text-align: center;
  max-width: 400px;
  flex-direction: column;
}
.content-error-section img.error-image {
  height: 320px;
  display: block;
}

.content-error-section h2.error-title {
  color: #171717;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}
.content-error-section p.error-description {
  font-size: 14px;
  font-weight: 400;
  color: #656566;
}

.page-not-found,
.content-error-section {
  min-height: calc(100dvh - 118px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
}

.page-not-found h2.not-found-title {
  font-size: 20px;
  margin: 0;
  font-weight: 400;
  color: #000;
}

@media screen and (min-width: 768px) {
  .page-not-found,
  .landing-block,
  .content-error-section {
    min-height: calc(100dvh - 130px);
  }
}

@media screen and (max-width: 767px) {
  .content-section {
    padding: 30px 0;
  }
  .content-section .content-title {
    font-size: 19px;
  }
  .header-block .brand-logo {
    height: 32px;
  }
  .header-block {
    padding: 16px 0;
  }
  .content-section .graphics-bg img {
    height: 258px;
  }
  .copyright-text {
    text-transform: lowercase;
  }
  .main-section {
    min-height: calc(100dvh - 64px - 52px);
  }

  .content-error-section img.error-image {
    height: 228px;
  }
  .content-error-section h2.error-title {
    font-size: 16px;
    margin-bottom: 12px;
  }
}
